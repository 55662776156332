import { useEffect, useContext, lazy, Suspense } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Spin } from "antd";
// import config
import { ROUTE, ALL_ROUTES } from "./config/common";
import UnauthenScreen from "./pages/unAuthenScreen";
import ExpiredFileScreen from "./pages/ExpiredFileScreen";
import PreviewFileScreen from "./pages/PreviewFileScreen";
// import component
import Navbar from "./components/common/Navbar";
import CustomModal from "./components/common/CustomModal";
import Sidebar from "./components/common/Sidebar";
import ProtectedRoute from "./components/common/ProtectedRoute";
// import gql
import { useLazyQuery } from "@apollo/client";
import { PROFILE } from "./graphql/query";
import { checkLogin } from "./utilities/authenUtil";

// Language cookie import
import Cookies from "js-cookie";
import psl from "psl";
import { isLocalhost } from "./utilities/authenUtil";

// import context
import {
  CommonContext,
  CommonDispatchContext,
} from "./stateManagement/context/commonContext";
import { ACTIONS } from "./stateManagement/actions/commonAction";
// import style
import "./styles/App.css";
import "./styles/theme.css";
import "antd/dist/antd.min.css";
import { matchPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import RecordVoice from "./components/preview-survey/choices/RecordVoice";

const HomeScreen = lazy(() => import("./pages/HomeScreen"));
const SharedWithMeScreen = lazy(() => import("./pages/SharedWithMeScreen"));
const RewardScreen = lazy(() => import("./pages/RewardScreen"));
const RewardDetailScreen = lazy(() => import("./pages/RewardDetailScreen"));
const RewardViewReportScreen = lazy(() =>
  import("./pages/RewardViewReportScreen")
);
const ProjectDetailScreen = lazy(() => import("./pages/ProjectDetailScreen"));
const AccountScreen = lazy(() => import("./pages/AccountScreen"));
const PreviewSurveyScreen = lazy(() => import("./pages/PreviewSurveyScreen"));
const StudiesScreen = lazy(() => import("./pages/StudiesScreen"));
const QACreationScreen = lazy(() => import("./pages/QACreationScreen"));
const NotfoundScreen = lazy(() => import("./pages/Notfound"));

function App() {
  const { i18n } = useTranslation();
  const dispatch = useContext(CommonDispatchContext);
  const { globalLoading } = useContext(CommonContext);
  const [getProfile] = useLazyQuery(PROFILE, {
    onCompleted: (data) => {
      const lang = data?.profile?.language === "THAI" ? "th" : "en";
      i18n.changeLanguage(lang);
      const currentLang = Cookies.get("lang");
      if (lang !== currentLang) {
        const domain = psl.get(window.location.hostname);
        Cookies.set("lang", lang, {
          domain: !isLocalhost ? `.${domain}` : "",
        });
      }
      dispatch({
        type: ACTIONS.SET_CURRENT_USER,
        currentUser: {
          ...data?.profile,
          fullname: `${
            data?.profile?.firstname ? data?.profile?.firstname : ""
          } ${data?.profile?.middlename ? data?.profile?.middlename : ""} ${
            data?.profile?.lastname ? data?.profile?.lastname : ""
          }`,
        },
      });
    },
  });
  const { pathname } = useLocation();
  const { isLogin: loggedIn } = checkLogin();
  useEffect(() => {
    if ((loggedIn && pathname !== ROUTE.UNAUTHEN) || pathname ===  ROUTE.PREVIEWFILE && ALL_ROUTES.includes(pathname)) {
      getProfile();
    }
  }, [getProfile, loggedIn, pathname]);

  const hideNavRouteList = [
    ROUTE.UNAUTHEN,
    ROUTE.PREVIEW_SURVEY,
    ROUTE.STUDIES,
    ROUTE.EXPIREDFILE,
    ROUTE.PREVIEWFILE,
    ROUTE.NOTFOUND
  ];
  const hideSidebarRouteList = [
    ROUTE.ACCOUNT.ROOT,
    ROUTE.UNAUTHEN,
    ROUTE.QA_CREATION,
    ROUTE.PREVIEW_SURVEY,
    ROUTE.STUDIES,
    ROUTE.EXPIREDFILE,
    ROUTE.PREVIEWFILE,
      ROUTE.NOTFOUND
  ];
  const hideGlobalLoadRouteList = [ROUTE.UNAUTHEN,ROUTE.EXPIREDFILE, ROUTE.PREVIEWFILE, ROUTE.NOTFOUND];
  const splitPathname = pathname?.split("/").filter((a) => a);
  return (
    <div className="App">
      {globalLoading && ALL_ROUTES.includes(pathname) &&
        !hideGlobalLoadRouteList.some((c) =>
          matchPath({ path: c }, pathname)
        ) && (
          <div id="global-loading">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} />} />
          </div>
        )}
      <CustomModal />
      {!ALL_ROUTES.includes(pathname) || hideNavRouteList.some((c) => matchPath({ path: c }, pathname)) ? null : (
        <Navbar />
      )}
      <div id="app-body-wrapper">
        {!ALL_ROUTES.includes(pathname) || hideSidebarRouteList.some((c) =>
          matchPath({ path: c }, pathname)
        ) ? null : (
          <Sidebar />
        )}
        <div
          id="content-container"
          className={splitPathname.map((name) => `${name}-screen`).join(" ")}
        >
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 50 }} />}
                />
              </div>
            }
          >
            <Routes>
              <Route
                path={ROUTE.PROJECTS}
                element={
                  <ProtectedRoute>
                    <HomeScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.PROJECT_DETAIL}
                element={
                  <ProtectedRoute>
                    <ProjectDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.SHARED_WITH_ME}
                element={
                  <ProtectedRoute>
                    <SharedWithMeScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.REWARDS}
                element={
                  <ProtectedRoute>
                    <RewardScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.REWARDS_CREATE}
                element={
                  <ProtectedRoute>
                    <RewardDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.REWARD}
                element={
                  <ProtectedRoute>
                    <RewardDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.REWARD_REPORT}
                element={
                  <ProtectedRoute>
                    <RewardViewReportScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.SHARED_WITH_ME_DETAIL}
                element={
                  <ProtectedRoute>
                    <ProjectDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.ACCOUNT.ROOT}
                element={
                  <ProtectedRoute>
                    <AccountScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.QA_CREATION}
                element={
                  <ProtectedRoute>
                    <QACreationScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.PREVIEW_SURVEY}
                element={
                  <ProtectedRoute>
                    <PreviewSurveyScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.STUDIES}
                element={
                  <ProtectedRoute>
                    <StudiesScreen />
                  </ProtectedRoute>
                }
              />
               <Route
                path={ROUTE.EXPIREDFILE}
                element={
                  <ExpiredFileScreen />
                }
              />
              <Route
                path={ROUTE.PREVIEWFILE}
                element={
                  <PreviewFileScreen />
                }
              />
               {/* <Route
                path={ROUTE.UNAUTHEN}
                element={
                  <RecordVoice
                    question={{ uid: "asdasd", uuid: "asdasdasd", answer: [] }}
                    answers={{ uid: null, label: null, value: null }}
                    onAnswer={() => {}}
                  />
                }
              /> */}
              <Route path={ROUTE.UNAUTHEN} element={<UnauthenScreen />} />
              {/* default route */}
              <Route path={ROUTE.NOTFOUND} element={<NotfoundScreen />} />
              <Route path="*" element={<Navigate to={ROUTE.PROJECTS} />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default App;
