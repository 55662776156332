const DEVICE = {
  LAPTOP: "Laptop",
  TABLET: "Tablet",
  MOBILE: "Mobile",
};

const ROUTE = {
  PROJECTS: "/projects",
  PROJECT_DETAIL: "/projects/:id",
  SHARED_WITH_ME: "/shared-with-me",
  REWARDS: "/rewards",
  REWARDS_CREATE: "/rewards/create",
  REWARD: "/reward/:id",
  REWARD_REPORT: "/reward/:id/report",
  SHARED_WITH_ME_DETAIL: "/shared-with-me/:id",
  ACCOUNT: {
    ROOT: "account",
    MAIN_PAGE: "/account",
    PERSONAL_DATA: "personal-data",
    PASSWORD: "password",
    CASH_DETAIL: "cash-detail",
    MY_EARNING: "my-earning",
    LANGUAGE: "language",
  },
  UNAUTHEN: "/unauthen",
  EXPIREDFILE: "/expiredfile",
  PREVIEWFILE: "/preview-file",
  PREVIEW_SURVEY: "/preview-survey/:id",
  PREVIEW_SURVEY_ROOT: "/preview-survey",
  QA_CREATION: "qa-creation/:id",
  STUDIES: "/studies/:id",
  NOTFOUND: '/404'
  // STUDIES: 'studies',
  // SUBMISSIONS: 'submissions',
  // SCREENING_QUESTION: 'screening-question',
  // ACCOUNT: 'account',
};

const ALL_ROUTES = [
  "/projects",
  "/projects/:id",
  "/shared-with-me",
  "/rewards",
  "/rewards/create",
  "/reward/:id",
  "/reward/:id/report",
  "/shared-with-me/:id",
  "/account",
  "/unauthen",
  "/expiredfile",
  "/preview-file",
  "/preview-survey/:id",
  "/preview-survey",
  "qa-creation/:id",
  "/studies/:id"
];

const QACREATION_TABS = {
  BUILDER: "qa-builder",
  LOGIC: "project-logic",
  SETTING: "project-setting",
};

const FORMAT = {
  DATE_FORMAT_WITH_TIME: "DD MMM YYYY, HH:mm",
  DATE_FORMAT_WITHOUT_TIME: "MMM DD, YYYY",
  ANOTHER_DATE_FORMAT_WITHOUT_TIME: "MMM DD, YYYY HH:mm",
  DATE_FORMAT: "DD MMM YYYY",
  DATE_FORMAT_WITH_SLASH: "DD/MM/YYYY",
  TIME_FORMAT: "LT",
};

const PROJECT_STATUS = {
  ACTIVE: "ACTIVE",
  APPROVED: "APPROVED",
  APPROVING: "APPROVING",
  COMPLETED: "COMPLETED",
  DRAFT: "DRAFT",
  PAUSED: "PAUSED",
  PENDING: "PENDING",
  STOPPED: "STOPPED",
  REJECT: "REJECT",
};
const PAYMENT_STATUS = {
  PAID: "PAID",
  PENDING: "PENDING",
};
const REWARD_STATUS = {
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  APPROVED: "APPROVED",
  APPROVING: "APPROVING",
  COMPLETED: "COMPLETED",
  DISAPPROVE: "DISAPPROVE",
  PAUSED: "PAUSED",
  PENDING: "PENDING",
  STOPPED: "STOPPED",
  REJECT: "REJECT",
};

const PROJECT_DETAIL_STATUS = {
  COMPLETED: 0,
  IN_PROGRESS: 1,
  TOO_QUICK: 2,
  TOO_SLOW: 3,
  TIME_OUT: 4,
  CANCEL: 5,
  QUIT: 6,
};
const REDEEM_DETAIL_STATUS = {
  COMPLETED: "COMPLETED",
  IN_PROGRESS: "IN_PROGRESS",
  TIME_OUT: "TIME_OUT",
};

const PROJECT_ACTIONS = {
  ACTIVE: "active",
  DUPLICATE: "duplicate",
  RENAME: "rename",
  MOVE: "move",
  DOWNLOAD: "download",
  EDIT: "edit",
  PREVIEW: "review",
  SHARE: "share",
  VIEW_REPORT: "view-report",
  DELETE: "delete",
  PAUSE: "pause",
  RESUME: "resume",
  STOP: "stop",
  CANCEL: "cancel",
};
const REWARD_ACTIONS = {
  EDIT: "edit",
  VIEW_DETAIL: "view-detail",
  VIEW_REPORT: "view-report",
  DOWNLOAD: "download",
  DELETE: "delete",
};

const PROJECT_DETAIL_ACTIONS = {
  ADD_TO_WHITE_LIST: "add-to-white-list",
  ADD_TO_BLACK_LIST: "add-to-black-list",
  PAUSE: "pause",
  RESUME: "resume",
  STOP: "stop",
  DUPLICATE: "duplicate",
  DOWNLOAD: "download",
  DELETE: "delete",
};

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const CONSTANT_VALUE = {
  LIMIT_PROJECT_TABLE: 10,
  LIMIT_REWARD_TABLE: 10,
  LIMIT_NOTI: 5,
  LIMIT_SUBMISSOIN_SURVEY_TABLE: 10,
  LIMIT_REDEMPTION_TABLE: 10,
  DEBOUNCE_SAVE_ANSWER: 300,
  AUTO_SAVE_INTERVAL: 5,
  LIMIT_REPORT_TEXT: 10,
};

const LOGIC_TYPE = {
  CUBE: "CUBE",
  DUMMY_CUBE: "DUMMY_CUBE",
  BRANCH: "BRANCH",
  SHUFFLE: "SHUFFLE",
  END_PROJECT: "END_PROJECT",
};

const BRANCH_TYPE = {
  QUESTION: "QUESTION",
  TRUE_FALSE: "TRUE_FALSE",
};

const NEXT_LOGIC_OPERATION = {
  NEXT_LEVEL: "NEXT_LEVEL",
  PREV_LEVEL: "PREV_LEVEL",
  NEXT_LOGIC: "NEXT_LOGIC",
};

const QUESTION_TYPE = {
  TEXT_ENTRY: "TEXT_ENTRY",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  MATRIX_TABLE: "MATRIX_TABLE",
  FORM_FIELD: "FORM_FIELD",
  RANK_ORDER: "RANK_ORDER",
  SLIDE_BAR: "SLIDE_BAR",
  RATING: "RATING",
  RECORD_VIDEO: "RECORD_VIDEO",
  RECORD_VOICE: "RECORD_VOICE",
  IMAGE: "IMAGE",
  TEXT: "TEXT",
  VIDEO: "VIDEO",
  PAGE_BREAK: "PAGE_BREAK",
};

const MATRIX_TYPE = {
  LIKERT: "LIKERT",
  BIPOLAR: "BIPOLAR",
};

const ANSWER_TYPE = {
  SING_LINE: "SING_LINE",
  MULTIPLE_LINE: "MULTIPLE_LINE",
  RADIO_BUTTON: "RADIO_BUTTON",
  CHECK_BOX: "CHECK_BOX",
  SELECT_BOX: "SELECT_BOX",
  DROPDOWN: "DROPDOWN",
  ALLOW_ONE_ANSWER: "ALLOW_ONE_ANSWER",
  ALLOW_MULTIPLE_ANSWERS: "ALLOW_MULTIPLE_ANSWERS",
  DROPDOWN_LIST: "DROPDOWN_LIST",
  DRAG_AND_DROP: "DRAG_AND_DROP",
};

const BRANCH_CONDITION = {
  IS_SELECTED: "IS_SELECTED",
  IS_NOT_SELECTED: "IS_NOT_SELECTED",
  IS_EQUAL_TO: "IS_EQUAL_TO",
  IS_NOT_EQUAL_TO: "IS_NOT_EQUAL_TO",
  IS_GREATER_THAN: "IS_GREATER_THAN",
  IS_GREATER_THAN_OR_EQUAL_TO: "IS_GREATER_THAN_OR_EQUAL_TO",
  IS_LESS_THAN: "IS_LESS_THAN",
  IS_LESS_THAN_OR_EQUAL_TO: "IS_LESS_THAN_OR_EQUAL_TO",
  IS_EMPTY: "IS_EMPTY",
  IS_NOT_EMPTY: "IS_NOT_EMPTY",
  IS_CONTAIN: "IS_CONTAIN",
  IS_NOT_CONTAIN: "IS_NOT_CONTAIN",
  TRUE: "TRUE",
  FALSE: "FALSE",
};

const CONDITION_MODE = {
  SCALE: "SCALE",
  COUNT: "COUNT",
  RECODE: "RECODE",
  SPECIFIC: "SPECIFIC",
};

const PAYMENTS_TYPE = {
  CASH: "CASH",
  ICREDIT: "ICREDIT",
};

const REPORT_COLORLIST = [
  "#4D751F",
  "#F49AC0",
  "#02A89E",
  "#1E82C3",
  "#F59678",

  "#014171",
  "#00A652",
  "#92278F",
  "#949599",
  "#07698F",

  "#FFF79A",
  "#F4989D",
  "#ED1B24",
  "#01652F",
  "#FEF200",

  "#00ADEF",
  "#C4DF9C",
  "#ED145B",
  "#302C2D",
  "#01665E",

  "#580054",
  "#7BCBC8",
  "#8F5501",
  "#BD8DBF",
  "#8B0305",

  "#6ED0F7",
  "#ED008C",
  "#8781BD",
  "#968D00",
  "#7EA8DA",

  "#8DC73F",
  "#2E3192",
  "#FEC689",
  "#82CA9C",
  "#160959",

  "#C4C4C4",
  "#A155B9",
  "#F7941D",
  "#8B0051",
  "#F765A3",
];

export {
  DEVICE,
  ROUTE,
  FORMAT,
  PROJECT_STATUS,
  REWARD_STATUS,
  PAYMENT_STATUS,
  PROJECT_ACTIONS,
  REWARD_ACTIONS,
  PROJECT_DETAIL_ACTIONS,
  PROJECT_DETAIL_STATUS,
  REDEEM_DETAIL_STATUS,
  PASSWORD_REGEX,
  CONSTANT_VALUE,
  QACREATION_TABS,
  LOGIC_TYPE,
  BRANCH_TYPE,
  NEXT_LOGIC_OPERATION,
  QUESTION_TYPE,
  MATRIX_TYPE,
  ANSWER_TYPE,
  BRANCH_CONDITION,
  CONDITION_MODE,
  PAYMENTS_TYPE,
  REPORT_COLORLIST,
  ALL_ROUTES
};
